/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  /* font-family: 'Enriqueta', serif; */
  font-family: "Open Sans", sans-serif;
  /* background: #151515; */
  background: #151515;
  color: #ffffff;
}
body::-webkit-scrollbar {
  display: none;
}

/*
=============== 
Navbar
===============
*/

.box {
  /* position: absolute; */
  top: 400px;
  left: 50%;
  /* top: 50%; */
  transform: translate(-50%, -50%);
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.buy__opensea {
  background: white !important;
  border: 1px solid #e0e0e0;
  color: black !important;
}
.nav-toggle {
  font-size: 1.5rem;
  color: var(--clr-primary-5);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}
.nav-toggle:hover {
  color: var(--clr-primary-1);
  transform: rotate(90deg);
}
.logo {
  height: 40px;
}

.social-icons {
  display: none;
}
.links-container {
  /* display: none; */
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
}
.show-container {
  /* height: 10rem; */
  display: flex;
}

.links {
  padding: 7px;
  cursor: pointer;
}
@media (min-width: 800px) {
  .nav-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 1rem; */
  }
  .nav-header {
    padding: 0;
  }
  .nav-toggle {
    display: none;
  }
  .links-container {
    height: auto !important;
  }
  .links {
    display: flex;
  }
  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
  }
  .social-icons {
    display: flex;
  }
  .social-icons a {
    margin: 0 0.5rem;
    color: var(--clr-primary-5);
    transition: var(--transition);
  }
  .social-icons a:hover {
    color: var(--clr-primary-7);
  }
}

/* ======End Of header======== */

.title {
  font-family: "Enriqueta", serif;
}

/* ====Community====== */

.community {
  background-image: url("./assets/img/community.png");
  height: 100%;
  background-size: cover;
  overflow: hidden;
}

/*======= Roadmap====  */

.roadmap {
  background-image: url("./assets/img/roadmap.png");
  height: 100%;
  background-size: cover;
  overflow: hidden;
}

.custom {
  left: -50px;
}

.subscribe__btn {
  top: 500px;
}

/* .hero__container {
  height: 80vh !important;
  width: 100vw;
} */

/* .hero {
  height: 85vh;
  width: 100%;
} */

@media (max-width: 800px) {
  .hero {
    height: 93vh;
    object-fit: cover;
  }

  .hero__mobile {
    background-image: url("./assets/img/mobile.png");
    height: 100%;
    background-size: cover;
    /* background-position: center center; */
    overflow: hidden;
  }

  .header__container {
    display: inline-block;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.93);
    z-index: 999;
  }

  .subscribe__btn {
    top: 14px;
  }
}

/* .hero {
  background-image: url("./assets/img/home.png");
  height: 100%;
  background-size: cover;
  overflow: hidden;
} */

.gif {
  background-image: url(./assets/img/backgif.gif);
  background-size: 100% 100%;  
  /* 115 */
  background-repeat: no-repeat;
  padding-bottom: -90px;
  
}



.vol {
  background: rgba(56, 142, 60, 0.1);
  /* Green/700 - #388E3C */

  border: 1px solid #388e3c;
  box-sizing: border-box;
  border-radius: 8px;
}

.vol2 {
  background: rgba(116, 116, 116, 0.1);
  border: 1px solid #747474;
  box-sizing: border-box;
  border-radius: 8px;
  color: #393838;
}

/* .hero__mobile {
  background-image: url("./assets/img/heromobile.png");
  height: 100%;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
} */

.faq__close {
  background: rgba(56, 142, 60, 0.3);

  border: 1px solid #388e3c;
  box-sizing: border-box;
  border-radius: 10px;
}

.faq__open {
  background: #388e3c;
  border-radius: 10px;
}

.index {
  z-index: -10;
}

/* =====Header Mobile===== */

.header__container {
  /* display: none; */
}
